import * as FormValidation from "../../formvalidation/js";
import * as FormValidationBootstrap from "../../formvalidation/js/plugins/Bootstrap";
import * as FormValidationMandatoryIcon from "../../formvalidation/js/plugins/MandatoryIcon";

export class Validator {

    static getDefaultPlugins() {
        return {
            trigger: new FormValidation.plugins.Trigger(),
            bootstrap: new FormValidationBootstrap.default(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
            mandatoryIcon: new FormValidationMandatoryIcon.default({
                icon: 'fal fa-asterisk'
            }),
            icon: new FormValidation.plugins.Icon({
                valid: 'fal fa-check',
                invalid: 'fal fa-times',
                validating: 'fal fa-refresh'
            })
        };
    }

    static getDefaultAjaxPlugins() {
        return {
            trigger: new FormValidation.plugins.Trigger(),
            bootstrap: new FormValidationBootstrap.default(),
            submitButton: new FormValidation.plugins.SubmitButton(),
            mandatoryIcon: new FormValidationMandatoryIcon.default({
                icon: 'fal fa-asterisk'
            }),
            icon: new FormValidation.plugins.Icon({
                valid: 'fal fa-check',
                invalid: 'fal fa-times',
                validating: 'fal fa-refresh'
            })
        };
    }

}