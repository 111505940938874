import {Expandable, Alert, WebPush, Modal, Notification} from "./core";
import {RebateListing} from "./rebate-listing";
import {CouponListing} from "./coupon-listing";
import {DealListing} from "./deal-listing";
import {Listing} from "./listing";
import {ListingSearch} from "./listing-search";

declare var lozad: any;

export class Project {

    static lazyLoader = null;

    static initLazyLoader() {
        if (!this.lazyLoader) {
            this.lazyLoader = lozad();
        }
        this.lazyLoader.observe();
        $('[data-toggle=tooltip]').tooltip();
    }

    static init() {
        ListingSearch.init();
        Project.initLazyLoader();
        Alert.init();
        WebPush.init();
        Modal.init();
        Listing.init();
        RebateListing.init();
        CouponListing.init();
        DealListing.init();

        $(document).ajaxError(function (event, xhr, settings) {
            //Displays notification.
            if (xhr.status === 503) {
                Notification.error(xhr.responseJSON.message);
                if (typeof xhr.responseJSON.redirect !== 'undefined') {
                    setTimeout(function () {
                        window.location.href = xhr.responseJSON.redirect;
                    }, 5000);
                }
            } else if (xhr.status === 401) {
                const options = {
                    href: '/user/html/auth',
                    size: 'modal-lg'
                };

                if ($('body').hasClass('modal-open')) {
                    Modal.reload(options);
                } else {
                    Modal.open(options);
                }
            }
        });
    }

    static testimonials() {
        Expandable.init();
        const $slider = (<any>$('.testimonial-slider')).on('init afterChange', function () {
            Expandable.show();
        }).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        }).on('beforeChange swipe', function () {
            $slider.find('.expanded .expandable-trigger-more').trigger('click');
        });
    }

    /**
     * Verify if the password typed in the field is really strong to prevent the attacks of hackers.
     *
     * @param $field to evaluate
     * @param $indicator this should be a progress bar of bootstrap.
     * @param animated to indicate if the progress bar will be animated or not.
     * @param height of the progress bar
     * @param fontsize of the progress bar
     */
    static strongPasswordChecker($field: JQuery, $indicator: JQuery, animated: boolean, height, fontsize): void
    {
        $field.on('keyup', function (e) {
           e.preventDefault();
           let animatedClass = animated === true ? ' progress-bar-striped progress-bar-animated' : '';
            let evaluate = {"lowercase": 0, "uppercase": 0, "numbers": 0, "specialChars": 0};
           let value = $.trim($(this).val().toString());
           let chain = value.split("");
            let score = 0;
            if (value.length > 0) {
                $indicator.addClass('bg-danger' + animatedClass)
                    .parent().removeClass('d-none')
                    .addClass('animated fadeIn')
                    .css({
                        height: height,
                        fontSize: fontsize
                    });
            } else {
                $indicator.parent().addClass('d-none animated fadeOut');
            }
           for (let x = 0; x < chain.length; x++) {
               if (/[a-z]/.test(chain[x])) {
                   evaluate["lowercase"] < 1 && evaluate["lowercase"]++;
               } else if (/[A-Z]/.test(chain[x])) {
                   evaluate["uppercase"] < 1 && evaluate["uppercase"]++;
               } else if (/[0-9]/.test(chain[x])) {
                   evaluate["numbers"] < 1 && evaluate["numbers"]++;
               } else if (/[^a-zA-Z0-9\s]/.test(chain[x])) {
                   evaluate["specialChars"] < 1 && evaluate["specialChars"]++;
               }
           }
           $.each(evaluate, function (k, v) {
               score += v;
           });
           if (value.length >= 10) {
               score++;
           } else {
               score > 3 && score--;
           }
           switch (score) {
               case 1:
                   $indicator.css({
                       width: '20%'
                   }).html('Very weak')
                       .removeClass('bg-success bg-warning bg-info')
                       .addClass('bg-danger').parent()
                       .attr('data-original-title', "Your password is really weak.")
                       .tooltip("update");
                   break;
               case 2:
                   $indicator.css({
                       width: '40%'
                   }).html('Weak')
                       .removeClass('bg-success bg-warning bg-info')
                       .addClass('bg-danger').parent()
                       .attr('data-original-title', "Your password is improving but it is not enough.")
                       .tooltip("update");
                   break;
               case 3:
                   $indicator.css({
                       width: '60%'
                   }).html('Medium')
                       .removeClass('bg-success bg-danger bg-info')
                       .addClass('bg-warning').parent()
                       .attr('data-original-title', "You almost get it, but still needs a better password.")
                       .tooltip("update");
                   break;
               case 4:
                   $indicator.css({
                       width: '80%'
                   }).html('Normal')
                       .removeClass('bg-success bg-danger bg-warning')
                       .addClass('bg-info').parent()
                       .attr(
                           'data-original-title',
                           "Your password is almost ready, try to use all rules for password"
                       ).tooltip("update");
                   break;
               case 5:
                   $indicator.css({
                       width: '100%'
                   }).html('Very strong')
                       .removeClass('bg-warning bg-danger bg-info')
                       .addClass('bg-success').parent()
                       .attr('data-original-title', "Your password is strong now.")
                       .tooltip("update");
                   break;
               default:
                   break;
           }
        });
    }
}