export class Expandable {

    static init() {
        $(document).on('click', '.expandable-trigger-more', function (event) {
            event.preventDefault();

            const $button = $(this);
            const $expandable = $button.parents('.expandable');
            const $content = $expandable.find('.expandable-content');
            const $innerContent = $content.find('.expandable-content-inner');
            if ($expandable.hasClass('expanded')) {
                $content.find('.expandable-indicator').fadeIn();
                $content.css({
                    "height": $content.outerHeight()
                }).animate({
                    "height": $content.data('height')
                }, function () {
                    $button.html('<i class="fal fa-plus"></i>');
                    $expandable.removeClass('expanded');
                });
            } else {
                const height = $content.outerHeight();
                $content.find('.expandable-indicator').fadeOut();
                $content.css({
                    "height": height
                }).animate({
                    "height": $innerContent.outerHeight()
                }, function () {
                    $button.html('<i class="fal fa-minus"></i>');
                    $expandable.addClass('expanded');
                    $content.data('height', height);
                });
            }
        }).on('resize', function () {
            Expandable.show();
        });
    }

    static show() {
        $(document).find('.expandable').each(function () {
            const $expandable = $(this);
            if ($expandable.find('.expandable-content-inner').height() > $expandable.find('.expandable-content').height()) {
                $expandable.find('.expandable-indicator, .expandable-trigger-more').fadeIn('fast');
            }
        });
    }
}