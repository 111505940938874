export class Favorite {

    static add($listing) {
        const data: any = {
            id: $listing.data('id'),
            type: $listing.data('type')
        };

        return $.post('/buyer/ajax/favorite/add', data, 'json').then(function (response) {
            $(document).trigger("favorite:added", [ $listing ]);
            //The listing could be multiple times on the page (in search results and product modal for example)
            $('[id="' + $listing.attr('id') + '"]').find('.fa-heart')
              .removeClass('fal')
              .addClass('fas')
              .data('id', response.favorite.id);
        });
    }

    static remove($listing) {
        const $icon = $listing.find('.fa-heart');
        const data: any = {
            id: $icon.data('id')
        };

        return $.post('/buyer/ajax/favorite/remove', data, 'json').then(function () {
            $(document).trigger("favorite:removed", [ $listing ]);
            //The listing could be multiple times on the page (in search results and product modal for example)
            $('[id="' + $listing.attr('id') + '"]').find('.fa-heart')
              .removeClass('fas')
              .addClass('fal')
              .removeData('id');
        });
    }
}