import * as $ from "jquery";
import * as FormValidation from "../formvalidation/js";
import {Validator} from "./core";

export class TwoFactorAuth {

  static init() {
    this.initEvents($('#two-factor-auth'));
  }

  static validate($form) {
    const data = $form.serializeArray();
    return $.ajax({
      url: '/user/ajax/two-factor-auth/validate',
      type: 'POST',
      data: data
    });
  }

  static initEvents($form) {
    const fv = FormValidation.formValidation($form[0], {
      fields: {
        'code': {
          validators: {
            digits: {
              message: 'The code is invalid (only digits authorized).'
            },
            stringLength: {
              min: 6,
              max: 6,
              message: 'The code should be 6 characters long.'
            },
            notEmpty: {
              message: 'The code is required.'
            }
          }
        }
      },
      plugins: Validator.getDefaultAjaxPlugins()
    });
    fv.on('core.form.valid', () => {
      const $group = $form.find('.form-group');
      let submitting = false;
      if (!submitting) {
        submitting = true;
        $form.find('.alert.alert-danger').remove();
        $form.find('button[type=submit]').html('<i class="fal fa-spin fa-spinner"></i> Checking...');
        TwoFactorAuth.validate($form).then((response) => {
          if (!response.valid) {
            $group.prepend(`<div class="alert alert-danger text-left" role="alert"><i class="fal fa-exclamation-triangle"></i> The code you entered is invalid. Please try again.</div>`);
            $form.find('button[type=submit]').html('Continue');
          } else {
            window.location.reload();
          }
        }).fail(() => {
          $form.find('button[type=submit]').html('Continue');
        }).always(function () {
          submitting = false;
        });
      }
    });
  }

  static startTimer(duration: number, $display: JQuery, callback?: () => void) {
    let timer = duration;
    let counter = setInterval(function () {
      const minutes = parseInt(String(timer / 60), 10)
      const seconds = parseInt(String(timer % 60), 10);
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

      $display.find('b').text(formattedMinutes + ':' + formattedSeconds);

      if (--timer < 0) {
        timer = duration;
        clearInterval(counter);
        typeof callback === 'function' && callback();
      }
    }, 1000);
  }

  static loadTimer(duration, resend_available) {
    if (!resend_available) {
      const $display = $('#counter');
      TwoFactorAuth.startTimer(duration, $display, () => {
        $('.button-resend-code').removeClass('none');
        $display.remove();
      });
    }
  }
}