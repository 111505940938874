toastr.options.closeButton = true;
toastr.options.progressBar = true;
toastr.options.positionClass = 'toast-top-center';

$(function () {

    // Tooltips
    $(document).tooltip({
        selector: '[data-toggle="tooltip"]'
    });

    // Popovers
    $(document).popover({
        selector: '[data-toggle="popover"]'
    });

    // Mega menu
    $(document).on('click', '[aria-haspopup="true"]', function (event) {
        $(event.target).trigger('blur').parent('li').toggleClass('openMenu');
    }).on('click', function (event) {
        const $megaMenu = $('#mega-menu--link');
        if ($megaMenu.length > 0 && !$megaMenu[0].contains(event.target)) {
            $('[aria-haspopup="true"]').parent('li').removeClass('openMenu');
        }
    });

    //Add a focused class to form-control.
    $('.form-control').on("focus blur", function () {
        const el = $(this);
        const parent = el.parent();
        setTimeout(function () {
            parent.toggleClass("focused");
        }, 0);
    });

    //Animate On Scroll
    AOS.init();

    // Smooth scroll
    new SmoothScroll('.smooth-scroll', {
        offset: 81
    });

    RK.Project.init();

});