import {Modal, WebPush} from "./core";

export class RebateListing {

    static notify(id, listingId) {
        return $.post('/buyer/ajax/notification/manage', {
            id: id,
            listing_id: listingId
        });
    }

    static init() {
        $(document).on('click', '.listing-rebate .btn-notify', function (event) {
            event.preventDefault();
            event.stopPropagation();
            const $button = $(this).attr('disabled', 'disabled');
            const id = $button.data('id');
            const listingId = $button.parents('.listing-container').data('id');
            RebateListing.notify(id, listingId).then(function (response) {
                if (!$button.data('id')) {
                    $button.attr('data-id', response.id);
                    if (!WebPush.enabled) {
                        Modal.open({href: '/web-push/modal/manage'});
                    }
                }
                $('[id="' + $button.parents('.listing-container').attr('id') + '"]')
                    .find('.btn-notify')
                    .html(response.html);
            }).always(function () {
                $button.removeAttr('disabled');
            });
        }).on('click', '.share, .share .collapse, .release', function (event) {
            event.stopPropagation();
        }).on('submit', '.rebate-claim-form', function (event) {
            const $form = $(event.target);
            $form.find("button[type=submit]")
                .html("Redirecting...")
                .addClass("disabled");

            //We open the product page in another tab, refresh that page so the buyer can introduce his rebate key.
            setTimeout(function () {
                window.location.href = $form.data('url');
            }, 500);
        });
    }
}