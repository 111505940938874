import Clipboard from 'clipboard';

import {Favorite} from "./favorite";
import * as FormValidation from "../formvalidation/js";
import {Validator} from "./core";
import * as $ from "jquery";

export class CouponListing {

    static init() {
        $(document).on('click', '.listing-card .listing-coupon .share, .share .collapse',
            function (event) {
                event.stopPropagation();
            }).on('click', '.btn-working', function (event) {
            const $button = $(event.target);
            const $listing = $button.parents('.listing-single');
            $.ajax({
                url: '/buyer/ajax/coupon/working',
                method: 'POST',
                data: {
                    id: $listing.data('id'),
                    working: 1
                }
            }).then(function () {
                $button.siblings().find('i').removeClass('fas').addClass('fal');
                $button.find('i').removeClass('fal').addClass('fas');
            });
        }).on('click', '.btn-not-working', function (event) {
            const $button = $(event.target);
            const $listing = $button.parents('.listing-single');
            $.ajax({
                url: '/buyer/ajax/coupon/working',
                method: 'POST',
                data: {
                    id: $listing.data('id'),
                    working: 0
                }
            }).then(function () {
                 const $container = $('#coupon-form-container');
                 const $form = $('#form-feedback');
                 $button.siblings().find('i').removeClass('fas').addClass('fal');
                 $button.find('i').removeClass('fal').addClass('fas');
                 $container.fadeOut('fast', function () {
                      $form.fadeIn();
                 });
            });
        });

        new Clipboard('.btn-clipboard-listing-coupon').on('success', function (event) {
            const $button = $(event.trigger);
            $button.html('<i class="fal fa-spin fa-spinner"></i> Redirecting...');
            const $listing = $button.parents('.listing-single');
            $.ajax({
                url: '/buyer/ajax/coupon/click',
                method: 'POST',
                data: {
                    id: $listing.data('id')
                }
            }).then(function () {
                $button.siblings('.listing-meta')
                    .replaceWith(`
                        <div class="d-md-flex align-items-center justify-content-center">
                            <button data-e2e="coupon-did-work" class="btn btn-link btn-working btn-sm text-success">
                                <i class="fal fa-thumbs-up"></i> The coupon did work
                            </button>
                            <button data-e2e="coupon-didnt-work" class="btn btn-link btn-not-working btn-sm text-danger">
                                <i class="fal fa-thumbs-down"></i> The coupon didn't work
                            </button>
                        </div>`);
                setTimeout(function () {
                    window.open($listing.data('url'), '_blank');
                }, 1000);
            }).always(function () {
                setTimeout(function () {
                    $button.html('Copy & Go to Marketplace');
                }, 1000);
            });
        });
    }

    static validateReportCoupon() {
        const $form = $('#form-feedback');
        const $container = $('#coupon-form-container');
        $form.on('click', '.hide-feedback', function (e) {
            e.preventDefault();
            $form.fadeOut('fast', function () {
                $container.fadeIn();
            })
        });
        const fv = FormValidation.formValidation($form[0], {
            fields: {
                'subject': {
                    validators: {
                        notEmpty: {
                            message: 'The problem is required.'
                        },
                    }
                },
                'comments': {
                    validators: {
                        notEmpty: {
                            enabled: true,
                            message: 'The comments are required.'
                        },
                    }
                }
            },
            plugins: Validator.getDefaultAjaxPlugins()
        });

        $form.on('change', '.disabled-comment', function () {
            if ($(this).val() == 4) {
                fv.enableValidator('comments');
            } else {
                fv.disableValidator('comments');
            }
        });

        fv.on('core.form.valid', function() {

            $form.find('button[type=submit]')
                .html('<i class="fal fa-spin fa-spinner"></i> Sending...').addClass('disabled');

            const id = $form.find('input[name=id]').val();
            const subject = $form.find('select[name=subject]').val();
            const comments = $form.find('textarea[name=comments]').val();
            $.ajax({
                url: "/buyer/ajax/coupon/report-coupon",
                method: "POST",
                data: { id, subject, comments },
                success: function (response) {
                    $container.replaceWith(`
                        <div class="d-md-flex align-items-center justify-content-center">
                                    <small class="text-success"> ${response.message} </small>
                        </div>`
                    )
                }
            }).always(function () {
                $form.fadeOut('fast', function () {
                    $container.fadeIn();
                })
            });

        });
    }
}
