import {KeywordAutocomplete} from "./keyword-autocomplete";

export class ListingSearch {

    static init() {
        const $searchForm = $('#search-deals-form');
        (<any>$searchForm.on('submit', (e) => {
            e.preventDefault();
            let data = $searchForm.serializeArray();
            data = data.concat($advancedSearchForm.serializeArray());
            window.location.href = `/buyer/dashboard?${$.param(data)}`;
        }).find('select')).multiselect({
            maxHeight: 300,
            buttonWidth: '100%',
            buttonClass: 'btn btn-light',
            nonSelectedText: 'All categories',
            includeSelectAllOption: true,
            selectAllText: 'All categories',
            allSelectedText: 'All categories',
            dropRight: true,
            numberDisplayed: 1
        });

        const $advancedSearchForm = $('#advanced-search-deals-form');
        (<any>$advancedSearchForm.on('submit', (e) => {
            e.preventDefault();
            let data = $searchForm.serializeArray();
            data = data.concat($advancedSearchForm.serializeArray());
            window.location.href = `?${$.param(data)}`;
        }).on('click', '.btn-save-filters', function (e) {
            e.preventDefault();
            const data = Object.assign($searchForm.serializeArray(), $advancedSearchForm.serializeArray());
            $.post('/buyer/ajax/filters', data).then(function () {
                window.location.reload();
            });
        }).find('#marketplace-id')).multiselect({
            maxHeight: 300,
            buttonWidth: '100%',
            buttonClass: 'btn btn-outline-light',
            nonSelectedText: 'Marketplaces',
            includeSelectAllOption: true,
            selectAllText: 'All marketplaces',
            allSelectedText: 'All marketplaces',
            dropRight: true,
            numberDisplayed: 1
        });
        (<any>$advancedSearchForm.find('#type')).multiselect({
            maxHeight: 300,
            buttonWidth: '100%',
            buttonClass: 'btn btn-outline-light',
            nonSelectedText: 'Deal types',
            includeSelectAllOption: true,
            selectAllText: 'All deal types',
            allSelectedText: 'All deal types',
            dropRight: true,
            numberDisplayed: 1
        });

        KeywordAutocomplete.init();
    }
}