import * as $ from "jquery";
import * as FormValidation from "../formvalidation/js";

import {Validator} from "./core";
import {Project} from "./project";

export class User {

    static validateContact() {
        FormValidation.formValidation($('#contact-form')[0], {
            fields: {
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                },
                'name': {
                    validators: {
                        notEmpty: {
                            message: 'The name is required.'
                        },
                        stringLength: {
                            min: 2,
                            message: 'The name must be of minimum length 2 characters.'
                        }
                    }
                },
                'message': {
                    validators: {
                        notEmpty: {
                            message: 'The message is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateUserContact() {
        FormValidation.formValidation($('#contact-form')[0], {
            fields: {
                'message': {
                    validators: {
                        notEmpty: {
                            message: 'The message is required.'
                        }
                    }
                },
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateChromeExtensionFeedback() {
        const $message = $('#about-chrome-extension-form :input[name="message"]');
        $message.val($message.val().toString().trim());
        FormValidation.formValidation($('#about-chrome-extension-form')[0], {
            fields: {
                'message': {
                    validators: {
                        notEmpty: {
                            message: 'The message is required.'
                        }
                    }
                },
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                },
                'attachment[]': {
                    validators: {
                        file: {
                            maxFiles: 3,
                            message: "Maximum files allowed is 3"
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateSignIn() {
        const $form = $('#sign-in-form');
        const fv = FormValidation.formValidation($form[0], {
            fields: {
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                },
                'password': {
                    validators: {
                        notEmpty: {
                            message: 'The password is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultAjaxPlugins()
        });
        fv.on('core.form.valid', () => {
            $form.find('button[type=submit]')
              .html('<i class="fal fa-spin fa-spinner"></i> Signing in...')
              .addClass('disabled');
            $form.trigger('submit');
        });
    }

    static validateBuyerSimpleSignUp() {
        FormValidation.formValidation($('#buyer-sign-up-form')[0], {
            fields: {
                'name': {
                    validators: {
                        stringLength: {
                            min: 5,
                            message: 'The full name is too short.'
                        },
                        notEmpty: {
                            message: 'The full name is required.'
                        }
                    }
                },
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateSocialSignUp() {
        FormValidation.formValidation($('#social-sign-up-form')[0], {
            fields: {
                'name': {
                    validators: {
                        stringLength: {
                            min: 5,
                            message: 'The full name is too short.'
                        },
                        notEmpty: {
                            message: 'The full name is required.'
                        }
                    }
                },
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateSellerSocialSignUp() {
        FormValidation.formValidation($('#social-sign-up-form')[0], {
            fields: {
                'name': {
                    validators: {
                        stringLength: {
                            min: 5,
                            message: 'The full name is too short.'
                        },
                        notEmpty: {
                            message: 'The full name is required.'
                        }
                    }
                },
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateLostPassword() {
        FormValidation.formValidation($('#lost-password-form')[0], {
            fields: {
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateResetPassword() {
        const $form = $('#reset-password-form');
        FormValidation.formValidation($form[0], {
            fields: {
                'password1': {
                    validators: {
                        notEmpty: {
                            message: 'The password is required.'
                        },
                        stringLength: {
                            min: 10,
                            message: 'Your password is too short (10 characters minimum).'
                        },
                        regexp: {
                            regexp: /^\S*(?=\S*[\W])(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/,
                            message: 'The password should be at least one uppercase letter, one lowercase letter, one number and one special character (e.g. #@!&*%+).'
                        }
                    }
                },
                'password2': {
                    validators: {
                        notEmpty: {
                            message: 'The password confirmation is required.'
                        },
                        stringLength: {
                            min: 10,
                            message: 'Your password confirmation is too short.'
                        },
                        identical: {
                            compare: function() {
                                return $('input[name="password1"]').val();
                            },
                            message: 'The two passwords are not identical.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
        Project.strongPasswordChecker(
            $('input[name="password1"]'),
            $form.find('.progress-bar'),
            false,
            '1.01rem',
            '0.75rem'
        );
    }

    static validateSellerSignUp() {
        FormValidation.formValidation($('#seller-sign-up-form')[0], {
            fields: {
                'name': {
                    validators: {
                        stringLength: {
                            min: 5,
                            message: 'The full name is too short.'
                        },
                        notEmpty: {
                            message: 'The full name is required.'
                        }
                    }
                },
                'email': {
                    validators: {
                        emailAddress: {
                            message: 'This is not a valid email address.'
                        },
                        notEmpty: {
                            message: 'The email address is required.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

    static validateResetEmail(){
        FormValidation.formValidation($('#reset-email-address-form')[0], {
            fields: {
                'new_email_address': {
                    validators: {
                        notEmpty: {
                            message: 'Your new email address is required.'
                        },
                        emailAddress: {
                            message: 'Your new email address is invalid.'
                        }
                    }
                },
                'new_email_address_confirmation': {
                    validators: {
                        notEmpty: {
                            message: 'The confirmation for your new email address is required.'
                        },
                        emailAddress: {
                            message: 'The confirmation for your new email address is invalid.'
                        },
                        identical: {
                            compare: function() {
                                return $('input[name="new_email_address"]').val();
                            },
                            message: 'The new email address and its confirmation are different.'
                        }
                    }
                }
            },
            plugins: Validator.getDefaultPlugins()
        });
    }

}